import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import SubLayout from '../../components/layouts/SubLayout'
import Seo from '../../components/Seo'
import GridCardCollection from '../../components/GridCardCollection'

const StudyFieldsPage = ({ data }) => {
  const studyFields = data.allSanityStudyField.nodes

  return (
    <SubLayout>
      <Seo title='Study Fields' pathname='/study-fields/' />
      <StyledStudyFieldsPage>
        <h1>Study Fields</h1>

        <p className="breadcrumbs">
          <Link to='/'>Home</Link> {`>`}&nbsp;
          Study Fields
        </p>

        <p>Choose the study field you are interested in. Not sure?</p>
        <p>Contact us to chat to a Student Adviser, book an appointment or complete a career assessment.</p>

        <GridCardCollection data={studyFields} directorySlug='study-fields' />
      </StyledStudyFieldsPage>
    </SubLayout>
  )
}

export const studyFieldsPageQuery = graphql`
  query StudyFieldsPageQuery {
    allSanityStudyField(sort: {fields: title}) {
      nodes {
        title
        slug { current }
        featuredImage {
          asset { url }
        }
      }
    }
  }
`

const StyledStudyFieldsPage = styled.div`
  h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  p {
    line-height: 2;
  }
`

export default StudyFieldsPage
